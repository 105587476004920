$h1-font-size-sm-rebrand: $font-size-base * 2.125;
$omgui-font-family-h1: 'Amazon Ember Modern Display Bold';
$omgui-font-family-h2: 'Ginto';

body.rebrand {
  h1,
  .h1 {
    font-family: $omgui-font-family-h1, #{$font-family-sans-serif};
  }

  h2,
  .h2 {
    font-family: $omgui-font-family-h2, #{$font-family-sans-serif};
  }

  @include media-breakpoint-down(sm) {
    h1,
    .h1 {
      font-size: $h1-font-size-sm-rebrand;
    }
  }
}
